import shippingContent from './content'
import apiReport from 'public/src/pages/common/apiReport'
import schttp from 'public/src/services/schttp'
import { getLocalStorage } from '@shein/common-function'

class ShippingCenter {
  constructor() {
    this.caches = {}
    this.content = {}
    this.defaultAddressCountryId = ''
  }

  static getMallShippingFree({ mall = [], country_id = '', defaultAddressCountryId = '', address = null }) {
    let data = { mall, defaultAddressCountryId }
    if (!mall?.length) return Promise.resolve({ code: -2 })
    let localCache = null
    try {
      localCache = JSON.parse(localStorage.getItem('localCache'))
    } catch (error) {
      localCache = null
    }
    if (localCache) {
      data.localCache = localCache
    }
    if (country_id) {
      data.country_id = country_id
    }
    if(address) {
      data.address = address
    }
    if(window.cart_prime_product_code){
      data.cart_prime_product_code = window.cart_prime_product_code
    }
    let address_cache = null
    let addressCookie = getLocalStorage('addressCookie')
    try {
      address_cache = typeof addressCookie === 'string' ?  JSON.parse(addressCookie) : addressCookie
    } catch (error) {
      address_cache = null
    }
    if (address_cache) {
      data.address_cache = address_cache
    }
    return schttp({
      url: '/api/cart/postFreeMall/get',
      method: 'POST',
      data: data
    }).catch( () =>{
      return { code: -1 }
    })
  }

  async getMallShippingContent({ mall = [], shippingInfo = null, country_id, address = null }) {
    let resInfo = shippingInfo
    if(!resInfo) {
      resInfo = await ShippingCenter.getMallShippingFree({ mall,  defaultAddressCountryId: this.defaultAddressCountryId, country_id, address })
    }

    return this.handleShippingContent(resInfo)
  }

  handleShippingContent(resInfo){
    let result = {}
    if(resInfo?.code != 0) {
      if(typeof window != 'undefined') {
        resInfo.code == -1 && apiReport.report({
          apiPath: 'cart/free_shipping_request/error',
          err: resInfo.msg || ''
        })
      }
      return result
    }
    this.defaultAddressCountryId = '' + resInfo.defaultAddressCountryId || ''

    let { platform, mall_list, mall_transport_free_list } = resInfo?.info || {}
    if(platform){
      result.platform = new shippingContent([platform])
    }

    if(mall_list?.length){
      mall_list.forEach(item => {
        let shipInfo = item.free_list?.length ? item.free_list : item.freight_tips || []
        if(shipInfo.length) {
          result[item.mall_code] = new shippingContent(shipInfo)
        }
      })
    }

    if (mall_transport_free_list?.length) {
      result.mall_transport_free_list = mall_transport_free_list
    }

    return result
  }

  setCache(key, info) {
    this.caches[key] = {
      value: info,
      time: new Date().getTime()
    }
  }

  getCache(key) {
    if (this.caches && this.caches[key] && this.caches[key].value) {
      return this.caches[key].value
    } else {
      return null
    }
  }
}


const shippingCenter = typeof window != 'undefined' ? window.shippingCenter = window.shippingCenter || new ShippingCenter() : new ShippingCenter()

export { shippingCenter }
