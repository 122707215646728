/**
 * Promotion 促销中心新
 */
import { handleTransformPromotionTip } from './logic/transformPromotionTipNew'
import i18n from '../biz_helper/i18n'
import schttp from 'public/src/services/schttp'
import { template } from '@shein/common-function'
import { getSuggestedPriceTypeByCartItem } from 'public/src/pages/cart_new/utils/handler.js'

class PromotionCenterNew {
  constructor() {
    this.cache = {
      integrityPromotionInfoNew: {}
    }
    this.language = {}
  }

  /**
   * 通过商品列表获取promotion相关信息
   * @param {Array} goods [商品列表]
   * @param {Object} language [多语言]
   * @param {String} scence [来源，用于区分购物车和商品列表]
   * @param {Array} promotion_range [活动档次及差额信息]
   * @param {Object} isGetLable [获取促销标签]
   * @param {Object} isGetVipPrice [获取会员价格]
   * @param {Boolean} isAppPromotion [app专享活动]
   */
  async preHandlePromotion({
    goods = [],
    language = this.language || {},
    promotion_range = [],
    page = '',
    forceRequest = false,
    isSuggested = false
  } = {}) {
    if (!Object.keys(language).length) {
      this.language = await i18n.loadByQueue('promotion')
    } else {
      this.language = language
    }
    
    let { needRequestIds, productPromotionInfos } = this.handleGoods(goods, isSuggested)
    let integrityPromotionInfo = await this.getIntegrityPromotionInfo({ ids: needRequestIds, forceRequest })
    
    this.handleIntegrityPromotionInfo({ goods, integrityPromotionInfo, page, promotion_range, productPromotionInfos, isSuggested })

    return { integrityPromotionInfo }
  }

  getIntegrityPromotionInfo({ ids = [], forceRequest = false } = {}) {
    let requestIds = []
    let result = {}
    if (ids) {
      // 强制更新活动信息需清除缓存 - 目前用于下单页订单返券包金额展示
      if (forceRequest && this.cache?.integrityPromotionInfoNew) {
        this.cache.integrityPromotionInfoNew = {}
      }
      ids.forEach(id => {
        if (this.cache.integrityPromotionInfoNew[id] === undefined) {
          result[id] = {}
          requestIds.push(id)
        } else {
          result[id] = this.cache.integrityPromotionInfoNew[id]
          // result[id].list = []
        }
      })
    }
    return new Promise(resolve => {
      if (requestIds.length > 0) {
        if (typeof window == 'undefined') return resolve(result)

        schttp({
          url: `/api/cart/getPromotionById/get`,
          method: 'POST',
          data: { promotion_id: requestIds }
        }).then( res =>{
          if (res.code == '0') {
            let promotionInfoList = res.info?.list || []
            promotionInfoList.forEach(item => {
              // this.dealIntegrityPromotionInfo(item)
              item.list = []
              item.typeId = item.type_id
              // item.flash_type = 2
              result[item.promotion_id] = this.cache.integrityPromotionInfoNew[item.promotion_id] = item
            })
          }
          resolve(result)
        }).catch(()=>{
          resolve(result)
        })
      } else {
        resolve(result)
      }
    })
  }

  /**
   * 未勾选时 差额默认取门槛信息
   * @param promotion_range
   * @param promotion_item_info
   * @return {{next: number, range: number, diff: *, all_diff: []}|null}
   */
  handlePromotionRangeInfo(promotion_range, promotion_item_info) {
    let promotion_range_info = promotion_range.find(item => item.promotion_id == promotion_item_info.promotion_id) || null
    if (promotion_range_info) {
      return promotion_range_info
    }

    let all_diff = []
    promotion_item_info.rules?.forEach(rule => {
      let type = 0
      if ([1, 2].includes(+rule?.type)) {
        type = 1
      } else if ([3, 4].includes(+rule?.type)) {
        type = 2
      }
      all_diff.push({ type, value: rule.value, value_amount: rule.value_amount })
    })

    if (all_diff.length) {
      return {
        range: 0,
        next: 1,
        all_diff: all_diff,
        diff: all_diff[0]
      }
    }
    return null
  }

  // dealIntegrityPromotionInfo (info) {
  //   const integrityContent = {}
  //   const { isCountdown, endTimestamp } = info
  //   if (isCountdown == '1' && endTimestamp) {
  //     integrityContent.countDown = typeof window != "undefined" ? PromotionUtils.countDownTime({ endTimestamp }) : endTimestamp * 1000
  //     integrityContent.countDownTimeStamp = endTimestamp * 1000
  //   }
  //   info.integrityContent = integrityContent
  // }

  // 根据活动id获取促销提示语
  async getTransformPromotionTip ({ language = {}, id = '' } = {}) {
    const ids = [].concat(id)
    const result = {}
    const integrityPromotionInfo = await this.getIntegrityPromotionInfo({ ids })
    ids.forEach(pid => {
      const typeId = integrityPromotionInfo[pid] ? integrityPromotionInfo[pid].type_id : ''
      if (typeId) result[pid] = handleTransformPromotionTip({ language, integrityPromotionInfo })
    })

    return result
  }

  /**
   * 落地服务端获取促销活动详情信息
   * @param {Object} res 促销信息
   */
  handleSSRIntegrityPromotionInfo({
    res = {},
    language = {},
    goods = [],
    promotion_range = [],
    page = '',
    locals = null,
    isSuggested = false
  }) {
    let integrityPromotionInfo = {}
    if (Object.keys(language).length) {
      this.language = language
    }
    let { productPromotionInfos } = this.handleGoods(goods, isSuggested)
   
    if (res.code == '0' && res.info?.list?.length > 0) {
      let promotionInfoList = res.info?.list || []
      promotionInfoList.forEach(item => {
        item.list = []
        item.typeId = item.type_id
        // item.flash_type = 2
        integrityPromotionInfo[item.promotion_id] = this.cache.integrityPromotionInfoNew[item.promotion_id] = item
      })
    }
    this.handleIntegrityPromotionInfo({ goods, integrityPromotionInfo, page, promotion_range, productPromotionInfos, locals, isSuggested })
    return { integrityPromotionInfo }
  }

  handlePromotionTag(promotionInfo, page = '', promotion) {
    let result = {}
    let { flash_type = '' } = promotionInfo || {}
    let { promotion_product_type, is_promotion, type_id, promotion_id, suit_value = {}, rule_num, promotion_logo_type } = promotion || {}
    if (promotion_product_type == '1') {
      if ([8, 14].includes(promotion_logo_type)) {
        result.typeIdText = promotion_logo_type === 8 ? this.language.SHEIN_KEY_PC_30882 : this.language.SHEIN_KEY_PC_32588
        result.typeIdIcon = page != 'checkout' ? ' >' : ''
      } else {
        result.typeIdText = this.language.SHEIN_KEY_PC_15751
      }
    } else if (promotion_product_type == 2) {
      result.typeIdText = this.language.SHEIN_KEY_PC_15966
    } else if ([1, 9, 15].includes(+type_id) && is_promotion == '1') {
      result.typeIdText = this.language.SHEIN_KEY_PC_30662
    } else if ([3].includes(+type_id)) {
      result.typeIdText = this.language.SHEIN_KEY_PC_19680
      result.typeIdIcon = page != 'checkout' ? ' >' : ''
    } else if ([10].includes(+type_id)) {
      let text = ''
      if(flash_type == 2) {
        text = this.language.SHEIN_KEY_PC_23050
      } else if(flash_type == 3) {
        text = this.language.SHEIN_KEY_PC_23052
      } else if(flash_type == 1) {
        text = this.language.SHEIN_KEY_PC_22561
      } else if(flash_type == 4 || flash_type == 5) {
        text = promotion?.tagText || this.language.SHEIN_KEY_PC_27297
      } else if(flash_type == 6) {
        text = this.language.SHEIN_KEY_PC_29870
      }
      result.typeIdText = text
      result.typeIdIcon = page != 'checkout' ? ' >' : ''
    } else if ([30].includes(+type_id)) {
      result.typeIdText = template(suit_value?.amountWithSymbol, this.language.SHEIN_KEY_PC_24559)
      result.typeIdIcon = page != 'checkout' ? ' >' : ''
      result.ruleNum = rule_num || ''
    } else if ([32].includes(+type_id)) {
      result.typeIdText = this.language.SHEIN_KEY_PC_27371
    } else if ([31].includes(+type_id) && promotion_logo_type == 3) {
      result.typeIdText = this.language.SHEIN_KEY_PC_27730
    }
    
    if( result.typeIdText ){
      result.typeId = type_id
      result.promotionId = promotion_id
      result.promotionLogoType = promotion_logo_type
      result.flashType = flash_type
    }
    return result
  }

  /**
   * 处理满赠活动
   * @param integrityPromotionInfo
   */
  async handleFullGiftType(integrityPromotionInfo = {}){
    if(typeof window == 'undefined') return
    let fullGiftAct = Object.values(integrityPromotionInfo).filter(item => {
      if (+item.type_id === 4) return item.promotion_logo_type !== 8
      return [2, 28].includes(+item.type_id)
    }) || []  // 全场满赠/部分满赠需要先请求各档赠品，根据赠品列表进行判断是否展示档次信息
    let fullGiftRequest = []
    fullGiftAct.forEach(promotion =>{
      let rulesLength = promotion.rules?.length || 0
      for (let i = 1; i <= rulesLength; i++) {
        const rule = promotion.rules?.[i - 1]
        let reqParam = { promotion_id: promotion.promotion_id, range: i, delivery_id: rule?.delivery_id }
        fullGiftRequest.push( this.getGiftProductList(reqParam))
      }
    })
    if(fullGiftRequest.length) {
      try {
        let giftProductListData = await Promise.all(fullGiftRequest)
        giftProductListData.forEach(item => {
          if(item.info){
            let targetPromotion = integrityPromotionInfo[item.info.promotion_id]
            if(targetPromotion) {
              targetPromotion.rules[(item.info.range - 1)].list = item.info.list || []
              targetPromotion.rules[(item.info.range - 1)].total = item.info.total || 0
            }
          }
        })
      } catch (e){
        console.log(e)
      }
    }
  }

  /**
   * 满赠活动需要优先获取赠品列表进行判断是否展示档次
   * @param promotion_id
   * @return {Promise<{}>}
   */
  getGiftProductList ({ promotion_id = '', range = '', delivery_id = '' }){
    if (typeof window == 'undefined') return Promise.resolve({})
    const requestData = {
      promotion_id: promotion_id,
      range: range,
      limit: 20,
      page: 1,
      delivery_id: delivery_id
    }
    return schttp({
      url: '/api/cart/getGiftProductList/get',
      method: 'POST',
      data: requestData
    })
  }

  /**
   * 判断合规站点pl\fr 商品属于倒挂 活动A类
   * 判断合规站点de 商品属于场景1、3、5才处理促销标签 pageId=1384306511
   * @param {*} item：商品 ； isSuggested：是否合规站点； promotion：促销活动信息
   * @returns 
   */
  getIsSuggestedInversionPromotionA({ item = {}, isSuggested = '' }) {
    if(isSuggested == 'special_de') {
      return  ![1, 3, 5].includes(item.suggestedPriceType)
    }
    if (isSuggested && +item.is_inversion === 1) {
      // 如果此时商品未参与B类活动，且未参与SHEIN CLUB，则此时展示的价格为黑色
      // 如果此时商品参与了B类活动，或参与了SHEIN CLUB，则此时可以展示B类活动和SHEIN CLUB的价格颜色、活动标签、价格前的icon，和线上保持一致
      return !(item.isPromotionB || item.isSheinClubItem)
    }
    return false
  }

  handleGoods(goods = [], isSuggested = false) {
    let needRequestIds = []
    let productPromotionInfos = {}
    goods.forEach(item => {
      let promotionIds = []
      let promotionTypeIds = []
      let promotionLogoTypeList = []
      item.normalFlashSale = null
      item.product.product_promotion_info?.forEach(promotion => {
        productPromotionInfos[promotion.promotion_id] = promotion
        promotionIds.push(promotion.promotion_id)
        promotionTypeIds.push(promotion.type_id)
        promotionLogoTypeList.push(promotion?.promotion_logo_type)
        if(promotion.type_id == 10) {
          item.normalFlashSale = promotion
        }
      })
      needRequestIds = needRequestIds.concat(promotionIds)
      item.promotionIds = promotionIds
      item.promotionTypeIds = promotionTypeIds
      item.promotionTag = {} // {typeId:xxx,typeIdText:'promotion'}
      item.promotionLogoTypeList = promotionLogoTypeList
      item.suggestedPriceType = isSuggested == 'special_de' ? getSuggestedPriceTypeByCartItem(item) : null
      // mock数据
      // item.product.insurable = 1
      // item.product.sale_price_before_insured = {
      //   amount: '8.95',
      //   amountWithSymbol: 'R$8,95'
      // }
      // item.product.insurable_time = 1665736954907

      if(item.product.insurable == 1) {
        item.promotionTag = {
          typeIdText: this.language.SHEIN_KEY_PC_23163,
          tipText: this.language.SHEIN_KEY_PC_23190
        }
      }
    })
    let needRequestIdsSet = new Set(needRequestIds)
    needRequestIds = [...needRequestIdsSet]

    return { needRequestIds, productPromotionInfos }
  }

  handleIntegrityPromotionInfo({ goods = [], integrityPromotionInfo = {}, page = '', promotion_range = [], productPromotionInfos = {}, locals = null, isSuggested = false }) {
    goods.forEach(cartItem => {
      cartItem.product.product_promotion_info?.forEach(promotion => {
        let promotionInfo = integrityPromotionInfo[promotion.promotion_id] || {}
        cartItem.isPromotionA = cartItem.isPromotionA || promotionInfo?.every_body_price_type == 1
        cartItem.isPromotionB = cartItem.isPromotionB || (promotionInfo?.every_body_price_type == 2 && !([2, 4, 13, 22, 28].includes(+promotionInfo?.type_id) && promotion.promotion_product_type == 3))
        if (promotion.stock_over == '1') {
          cartItem.limit_mode = promotionInfo.promotion_id
        }
      })
      let multiPromotionTags = []
      cartItem.product.product_promotion_info?.forEach(promotion => {
        let promotionInfo = integrityPromotionInfo[promotion.promotion_id] || {}
        if (promotion.type_id == 10) {
          cartItem.flashType = promotionInfo.flash_type // 1为普通闪购，2为新人闪购，3为特殊闪购
          cartItem.normalFlashSale.limit_mode = promotionInfo.limit_mode
        }
        let isSuggestedInversionPromotionA = this.getIsSuggestedInversionPromotionA({ item: cartItem, isSuggested })
        let promotionTag = Object.keys(cartItem.promotionTag).length == 0 && !isSuggestedInversionPromotionA && this.handlePromotionTag(promotionInfo, page, promotion)
        let multiPromotionTag = !isSuggestedInversionPromotionA && this.handleMultiPromotionTags(promotionInfo, promotion)
        if(Object.keys(multiPromotionTag).length > 0) {
          multiPromotionTags.push(multiPromotionTag)
        }
        if (promotionTag?.typeId) {
          cartItem.promotionTag = promotionTag
        }
      })
      // 避免ssr和客户端重复push
      if(cartItem.multiPromotionTags?.length <= multiPromotionTags.length || typeof cartItem.multiPromotionTags == 'undefined') {
        cartItem.multiPromotionTags = multiPromotionTags
      }
    })

    for (let key in integrityPromotionInfo) {
      integrityPromotionInfo[key].promotion_range_info = this.handlePromotionRangeInfo(promotion_range, integrityPromotionInfo[key]) // 活动门槛及差额
      integrityPromotionInfo[key].product_promotion_info = productPromotionInfos[key] // 商品下的促销字段
      integrityPromotionInfo[key].content = integrityPromotionInfo[key].type_id && handleTransformPromotionTip({
        language: this.language,
        promotionInfo: integrityPromotionInfo[key],
        locals: locals
      }) || {}
    }
  }
  /**
   * @description: 处理与promotionTag兼容的多个活动标签
   * @param {*} promotionInfo 来自于促销接口的活动详情
   * @param {*} page 所处页面
   * @param {*} promotion 来自于商品的活动详情
   * @return { Array } 
   */
  handleMultiPromotionTags(promotionInfo, promotion) {
    let tag = {}
    const { flash_type = '' } = promotionInfo || {}
    const { type_id, promotion_id } = promotion || {}
    if ([33].includes(+type_id)) {
      tag.typeIdText = this.language.SHEIN_KEY_PC_30873
      tag.tipText = this.language.SHEIN_KEY_PC_30875
      let catNameMulti = promotionInfo?.category_info?.[0]?.cat_name_multi
      if(catNameMulti) {
        tag.subText = template(catNameMulti, this.language.SHEIN_KEY_PC_30879)
      }
      if( tag.typeIdText ){
        tag.typeId = type_id
        tag.promotionId = promotion_id
        tag.flashType = flash_type
      }
    }
    return tag
  }
}

const promotionCenterNew = typeof window !== 'undefined' ? window.PromotionCenterNew = window.PromotionCenterNew || new PromotionCenterNew() : new PromotionCenterNew()

export { promotionCenterNew }
