export default class shippingContent {
  /**
     * @res showShipSupplement 是否展示免邮凑单入口
     * @res shippingInfoAvailable 可展示的邮寄项
     * 
     * --- 以下都是当前可用的邮费项 ---
     * @res isFree              是否是免邮 true 免邮， false 阶梯运费
     * @res shippingMethodName  运输方式
     * @res shippingTip         运输提示语
     * @res lackPrice           差额，距离免邮或者下一梯度的差额
     * @res isFreeShipping      满足免邮条件，无凑单入口true
     * @res activity_type      免邮活动：activity_type=1，政策免邮：activity_type=2，运费活动：activity_type=3
     * @res isFirstFreeShipping      是否首单免邮
     * @res firstFreeShipping      首单免邮信息
     * @res transportType      运输费方式类型
     *
     * @res _info 未封装的邮费信息
     */
  constructor(shippingInfo, freight_tips) {
    this.showShipSupplement = false
    this.shippingInfoAvailable = null

    this.shippingTip = ''
    this.lackPrice = null
    this.isFree = null
    this.isLadder = null
    this.isFreeShipping = false
    this.freight_tips = freight_tips || {}
    this.activity_type = ''
    this.isFirstFreeShipping = false
    this.firstFreeShipping = null
    this.transportType = ''

    this._info = shippingInfo

    this._getCommonContent()
  }

  /**
   * 处理免邮信息
   * @private
   * free_type： 1首单免邮 2普通免邮
   */
  _getCommonContent() {
    const shippingInfoAvailable = this.shippingInfoAvailable = this.getAvailable()
    if (!shippingInfoAvailable) return

    const { 
      tip, 
      is_free_shipping, 
      price, 
      charging_type, 
      diff, 
      diff_price, 
      desc, 
      shippingMethodName, 
      shipping_method_name, 
      activity_type, 
      free_type = 2, 
      show_count_down, 
      count_down_time,
      transport_type,
    } = shippingInfoAvailable

    this.transportType = transport_type
    if(free_type == 1) {
      this.isFirstFreeShipping = true
      this.showShipSupplement = Boolean(tip && +diff_price?.amount)
      this.isFree = !this.showShipSupplement
      this.isFreeShipping = !this.showShipSupplement
      this.firstFreeShipping = {
        showCountDown: show_count_down,
        countDownTime: count_down_time,
        endTimestamp: Date.parse(new Date()) / 1000 + count_down_time + 1, // 倒计时结束时间（毫秒）为了校准多加1秒,
        firstFreeShippingTip: tip.replace(/\[money]/g, diff_price?.amountWithSymbol || '')
      }
      this.lackPrice = diff_price
      return
    }
    this.shippingMethodName = shippingMethodName || shipping_method_name
    this.isFree = Boolean(is_free_shipping)
    this.activity_type = activity_type

    if (tip) {
      // 免邮
      this.showShipSupplement = Boolean(is_free_shipping == 0 && (price && price.amount > 0))

      this.shippingMethodName = shippingMethodName || shipping_method_name
      this.shippingTip = this.getFreeShippingTip()
      this.lackPrice = price
    } else if (desc) {
      // 阶梯邮费
      this.isLadder = true
      this.showShipSupplement = Boolean(charging_type == 3 && diff > 0)

      this.shippingMethodName = shippingMethodName || shipping_method_name
      this.shippingTip = desc
      this.lackPrice = diff_price
    }
    if(this.shippingTip && !this.showShipSupplement){
      this.isFreeShipping = true
    }
  }

  getFreeShippingTip() {
    const { tip, price } = this.shippingInfoAvailable
    return tip.replace('[money]', price.amountWithSymbol) || ''
  }

  getAvailable() {
    const availableInfos = this._info.filter(item => item.is_show === 1)
    return availableInfos && availableInfos.length >= 0 ? Object.assign({}, availableInfos[0], this.freight_tips) : null
  }
}
